    /*
        menu within invite panel, dashboard, project page
    */
@mixin userMenu {
    .menu-button {
        border: unset;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, .1);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        cursor: pointer;
        right: 0;
        bottom: 0;
    }

    .menu-button:hover,
    .button-active {
        background-color: rgba(0, 0, 0, .2);
    }

    .menu-button img {
        width: 15px;
        pointer-events: none;
    }

    .menu-list {
        min-width: 155px;
        position: absolute;
        list-style: none;
        right:0;
        bottom: -85%;
        background-color: var(--white);
        border-radius: 6px;
        padding: .5rem 0;
        display: none;
        box-shadow: 0px 2px 8px -2px #aaa;
        z-index: 2;

        li {
            font-size: var(--font-size-small);
            background-color: var(--white);

            &:hover {
                background-color: var(--grey5)
            }

            button[type='button'] {
                padding: .3rem 1.5rem;
                border: unset;
                width: 100%;
                min-width: max-content;
                height: 100%;
                border: unset;
                border-radius: unset;
                background-color: transparent;
                display: block;
                text-align: left;
                cursor: pointer;
                color: var(--grey1);
            }

            button[disabled] {
                color: var(--grey4);
            }
        }
    }
}
