@import '../_responsive';

@mixin alert {
	.alert-show {
		width: 100vw;
		height: 100vh;
		position: fixed;
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.2);
		top: 0;
		left: 0;
	}
	.alert-hide {
		display: none;
	}

	dialog.alert-window {
		background-color: var(--white);
		padding: 1.3125rem;
		border: 1px solid var(--grey6);
		box-sizing: border-box;
		border-radius: 10px;
		position: relative;
		width: 700px;
		box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
		max-height: 100vh;
		overflow-y: auto;

		h3 {
			text-align: center;
		}

		.tab-audiences,
		.tab-strategy,
		.tab-personas,
		.tab-motivations {
			details summary {
				margin-bottom: 0;
			}
		}
		.tab-personas {
			details summary .summary-text {
				max-width: 60%;
			}
		}

		details {
			padding: 0 1em;
			font-size: 14px;
			margin-bottom: 1em;
			font-weight: 500;
			color: var(--grey3);
			line-height: 18px;
			text-align: left;

			> details {
				margin-bottom: 0;
			}

			summary {
				margin-bottom: 1.5em;
				.summary-text {
					word-break: break-word;
					max-width: 92%;
					display: inline-block;
					vertical-align: middle;
				}
				img,
				button {
					vertical-align: middle;
					margin: 0 0.5em;

					&.multiple {
						margin-right: -0.5em;
					}
				}
				button {
					background-color: #fff;
					border: 1px solid var(--grey3);
					border-radius: 0.5em;
					font-size: small;
					padding: 0.5em;
				}
			}
		}
		textarea {
			width: 100%;
			padding: 1em;
			font-size: 16px;

			&.loading {
				background: url(/assets/loading.gif) no-repeat center;
				background-size: 48px;
			}
		}
		.word-count {
			text-align: right;
			margin-bottom: 2em;
		}
		.minimal-message {
			padding: 0 1rem;
			font-weight: 500;
			font-size: 16px;
			color: var(--grey3);
			line-height: 24px;
			text-align: center;
		}
		.nothing-selected-warning {
			font-weight: 500;
			font-size: 16px;
			color: var(--grey3);
			line-height: 24px;
			text-align: center;
			background-color: #ffffdd;
			border: 1px solid #dd9900;
			padding: 0.5em 1em;
			border-radius: 0.5em;
			margin-bottom: 2em;
			margin-top: -1em;
		}
		.text-preview,
		.text-preview-full-width,
		.text-preview-compact {
			font-size: 11px;
			font-style: italic;
			color: var(--grey4);
			vertical-align: middle;
			padding: 0 0 1rem 1rem;
			user-select: text;
		}
		.text-preview-compact {
			padding: 0 0 0 1rem;
			margin-bottom: 0;
		}
		.text-preview,
		.text-preview-compact {
			display: inline-block;
			max-width: 95%;
		}
		h5 {
			margin: 0;
			margin-top: 0.5rem;
			padding: 0;
		}
		img.screenshot {
			border: 1px solid #000;
			padding: 1rem;
		}
		.stakeholder {
			clear: both;

			img {
				float: left;
				padding-right: 0.5em;
				padding-bottom: 0.5em;
			}

			.name {
				font-weight: bold;
				margin-bottom: 0;
			}

			.description {
				margin-top: 0;
				font-size: 0.9em;
			}
		}
		.tabs {
			padding: 0 0.5em;

			button {
				border: 1px solid var(--grey3);
				//border-left: none;
				//border-bottom: none;
				background-color: #fff;
				cursor: pointer;
				padding: 0.5em 1em;
				font-size: var(--font-size-small);
				margin-bottom: -1px;
				margin-left: -1px;
				color: var(--grey3);
				font-weight: 500;
				border-top-right-radius: 0.4em;
				border-top-left-radius: 0.4em;
				&.active {
					background-color: var(--blue);
					color: var(--white);
				}

				//&:first-child {
				//    border-top-left-radius: 0.5rem;
				//    border-left: 1px solid var(--grey3);
				//}

				//&:last-child {
				//    border-top-right-radius: 0.5rem;
				//}
			}
		}
		.tab-content {
			height: 300px;
			overflow-y: scroll;
			width: 100%;
			padding: 1rem;
			border: 1px solid var(--grey4);
			margin: 0 0.5rem 2rem;

			.budget-container {
				[name='currency'] {
					width: 5em;
					float: left;
					margin-right: 1em;
				}

				&::after {
					content: '';
					clear: both;
				}
			}

			.image-list,
			.link-list,
			.text-list,
			.sourced-list {
				list-style: none;
				display: flex;
				flex-wrap: wrap;
				max-width: 580px;
				padding-left: 1em;

				img {
					vertical-align: middle;
					flex: 1;
					margin-right: 1em;
				}

				li {
					margin-bottom: 0.5em;
				}
			}

			.sourced-list {
				li {
					display: flex;
					align-items: flex-start;
					margin-bottom: 1em;

					div {
						display: flex;
						flex-direction: column;
						flex: 1;
						p {
							min-width: 100%;
						}
					}
				}
			}

			.count-tag {
				background-color: #eef;
				border-radius: 1em;
				padding: 0.2em 0.5em;
				margin-left: 0.5em;
				color: var(--blue);
				font-size: 12px;
				&.count-tag-zero {
					background-color: #eee;
					color: var(--dark);
				}
			}
			.synthesise-label,
			.inspire-label,
			.ideate-label,
			.source-label {
				font-size: 12px;
				display: block;
				margin-left: 16px;

				&.no-checkbox {
					margin-left: 12px;
				}
			}
			.synthesise-label,
			.synthesise-key {
				color: var(--fuchsia);
			}
			.ideate-label,
			.ideate-key {
				color: var(--blue);
			}
			.inspire-label,
			.inspire-key {
				color: var(--orange-dark);
			}

			p:first-child {
				margin-top: 0;
			}
		}
		.alert-message {
			padding: 3rem;
			font-weight: 500;
			font-size: 22px;
			color: var(--grey3);
			line-height: 28px;
			text-align: center;
		}
		.alert-buttons {
			text-align: center;
			margin: -1rem 0 1em 0;
			@include media('tablet') {
				padding: 0.5em;
			}
			button {
				border: 1px solid #7b7b7b;
				padding: 0.5rem 1.5rem;
				border-radius: 10px;
				cursor: pointer;
				background-color: var(--white);
				color: var(--grey3);
				margin: 0 0.5rem 0.2em;
				font-size: var(--font-size-medium);

				img {
					margin: 2px 15px 2px 0;
					vertical-align: -8px;
				}

				&:disabled {
					opacity: 0.5;
				}
			}
		}
	}
	.alert-close,
	.close-panel {
		width: 1em;
		height: 1em;
		position: absolute;
		top: 1.4em;
		right: 1.4rem;
		border: none;
		background-color: transparent;
		cursor: pointer;
		padding: 0;
		@include media('tablet') {
			top: 1em;
			right: 1.5em;
		}

		img {
			width: inherit;
		}
	}

	#chatbot-assistant-widget {
		transition: all 0.5s ease; /* Smooth transition for all properties */
	}

	.flowy-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		// height: 2.5em;
	}

	h2.flowy-header-title {
		font-style: normal;
		font-weight: 400;
		font-size: 1.2rem;
		line-height: 28px;
		color: var(--grey3);
		margin: 0 0.6rem 0 0;
	}

	.flowy-header-logo {
		// width: 32px;
		height: 32px;
	}

	.flowy-button {
		position: static;
	}

	.flowy-widget {
		// background-color: #f0f0f0;
		// border-radius: 1em;
		padding: 16px;
		box-shadow: var(--box-shadow);
		border: 1px solid var(--grey4);
		border-radius: var(--radius);
		background-color: var(--white);
		z-index: 1000;
		position: fixed;
		cursor: move;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	#chatbot-assistant-message {
		margin-top: 1em;
	}

	.flowy-widget-minimized {
		bottom: 2em;
		left: 12em;
		width: 150px;
		height: 32px;
		padding: 0;
		cursor: pointer; // finger pointer
		padding-left: 16px;
		padding-right: 16px;
		font-size: 0.8rem;
	}

	.flowy-widget-minimized .flowy-header-title {
		font-size: 0.8rem;
		margin: 0;
		text-align: center;
		flex: 1;
	}

	.flowy-widget-minimized .flowy-header-logo {
		height: 20px;
	}

	.flowy-widget-minimized .close-panel {
		height: 12px;
		width: 12px;
	}

	.flowy-widget-maximized {
		left: 50%;
		transform: translateX(-50%);
		width: 300px;
	}

	.flowy-widget-maximized button {
		cursor: pointer;
	}

	#guide .close-panel {
		border: none;
		background-color: var(--white);
		width: 25px;
		height: 25px;
		position: absolute;
		right: 38px;
		top: 32px;
		cursor: pointer;
	}
	button.primary {
		background-color: var(--blue) !important;
		color: var(--white) !important;
		border: 1px solid var(--grey7) !important;
	}
	.hidden {
		display: none !important;
	}
	.invisible {
		visibility: hidden;
	}

	@media all and (max-width: 1024px) {
		dialog.alert-window {
			.minimal-message {
				padding: 0 0.5em;
				font-size: var(--font-size-small);
			}

			textarea {
				padding: 0.5em 1em;
				font-size: 15px;
			}
		}
	}
}
