@use './components/header';
@use './components/alert';
@use './components/nonBlockingMessage';
@use './components/userList';
@use './components/tutorial';
@use './components/pageLoader';
@use './components/invitePanel';
@use './components/uploadPanel';
@use './components/userMenu';

:root {
	--black: #000000;
	--grey1: #272727;
	--grey2: #46464e;
	--grey3: #434559;
	--grey4: #767680;
	--grey5: #e3e1ec;
	--grey6: #91909a;
	--grey7: #f3f0f5;
	--grey8: #fefbff;
	--grey9: #828282;
	--white: #ffffff;
	--darkblue: #252762;
	--purple: #cc49ed;
	--success: #00955a;
	--warning: #f25131;

	// Base user colours:
	--fuchsia: #ea0f72;
	--orange: #f28b13;
	--red: #f23131;
	--green: #00c58a;
	--fuchsia-menu: #f1d8e3;
	--orange-menu: #f6e1c8;
	--red-menu: #fcd6d6;
	--green-menu: #b3f2df;

	// Additional user colours:
	--dark: #666666;
	--violet: #cc88ff;
	--yellow: #ffdd00;	
	--cyan: #88eeee;
	--dark-menu: #999999;
	--violet-menu: #eeccff;
	--yellow-menu: #ffffaa;
	--cyan-menu: #bbffff;

	// Extra user colours:
	--blue: #4354bf;
	--pink: #c71585;
	--lime: #88F666;
	--teal: #008080;
	--maroon: #800000;
	--turquoise: #30d5c8;
	--purple: #800080;
	--navy: #000080;
	--salmon: #ff8c69;
	--forestGreen: #228b22;
	--olive: #808000;
	--chocolate: #8b4513;
	--blue-menu: #93b4ff;
	--pink-menu: #eaa0c1;
	--lime-menu: #e0ff99;
	--teal-menu: #66cccc;
	--maroon-menu: #cc6666;
	--turquoise-menu: #a8eee9;
	--purple-menu: #cc99cc;
	--navy-menu: #9999cc;
	--salmon-menu: #ffccbb;
	--forestGreen-menu: #77c477;
	--olive-menu: #cccc99;
	--chocolate-menu: #d99663;

	// Unassigned user:
	--grey4-menu: #f4f4f5;

	--FUSCHIA: #ee44ee;
	--SALMON: #ff6655;
	--FOREST: #296640;
	--RED: #bb160d;
	--GREEN: #089c30;
	--GREENBLUE: #4394bf;
	--BLUE: #4354bf;
	--TAN: #bb6622;
	--LINK: #99ccee;
	--IDEATE: #aaffcc;

	--orange-dark: #e27b03;

	--main-font: Roboto, BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
	--font-size-small: calc((14 / 16) * 1rem); /* 14px */
	--font-size-default: calc((16 / 16) * 1rem); /* 16px */
	--font-size-medium: calc((18 / 18) * 1rem); /* 18px */
	--font-size-large: calc((24 / 16) * 1rem); /* 24px */
	--font-size-larger: calc((32 / 16) * 1rem); /* 32px */

	--bp-mobile: 320px;
	--bp-tablet: 768px;
	--bp-laptop: 1024px;
	--bp-desktop: 1920px;

	--radius: 10px;

	--button-small: 40px;
	--button-large: 80px;
	--button-larger: 90px;
	--button-height: 40px;
	--button-small-tablet: 2em;
	--button-large-tablet: 4em;
	--button-height-tablet: 2em;
	--button-height-mobile: 1.8em;

	--box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15), 0 0px 10px rgba(0, 0, 0, 0.05);
}

*,
*::after,
*::before {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	font-family: var(--main-font);
	text-rendering: optimizeLegibility;
}

body {
	margin: 0;
	padding: 0;
	-webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
	-webkit-user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */
	user-select: none;
}

textarea::placeholder {
	color: var(--grey4);
}

@include alert.alert;
@include pageLoader.pageLoader;
@include userList.userList;
@include userMenu.userMenu;

.introjs-helperLayer {
	box-shadow:
		rgb(33 33 33 / 80%) 0 0 1px 2px,
		rgb(33 33 33 / 10%) 0 0 0 5000px !important;
}

.system-notice {
	background-color: #efefff;
	border: 1px solid #bbbbff;
	padding: 0.5em 1em;
	border-radius: 0.5em;
}

.system-warning {
	background-color: #ffefef;
	border: 1px solid #a00;
	color: #a00;
	padding: 0.5em 1em;
	border-radius: 0.5em;
	margin: 0 2em;
}

.positive-notice {
	background-color: #efffef;
	border: 1px solid #bbffbb;
	padding: 1em 1.5em;
	border-radius: 0.5em;
	margin-bottom: 1em;
}

#landscape-warning {
	display: none;
}

@media (max-width: 700px) and (orientation: portrait) {
	#landscape-warning {
		position: fixed;
		z-index: 10000;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 2em 3em;
		color: #333;
		font-size: 20px;
		line-height: 30px;

		img {
			width: 176px;
			margin-bottom: 2em;
		}

		strong {
			font-size: 22px;
		}
	}
}

.ai-tooltip {
	display: none;
	position: absolute;
	z-index: 9999;
	padding: 0.2rem;
	border-radius: 6px;
	background-color: var(--white);
	border: 1px solid var(--grey8);
	box-shadow: var(--box-shadow);
	flex-direction: column;
	gap: 0.2rem;

	button {
		background-color: #e3e1ec;
		border-radius: 6px;
		color: var(--grey4);
		border: none;
		padding: 0.5rem;
		cursor: pointer;
	}
	button:hover {
		background-color: #d3d1dc;
	}
}

.menu-button--small {
	width: var(--button-small);
	height: var(--button-height);
	margin: 0 0.3rem;
	border: 1px solid var(--grey4);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: var(--font-size-large);
	color: var(--grey4);
	border-radius: 6px;
	background-color: var(--white);

	img {
		display: block;
		width: 1.2rem;
	}

	&.active,
	&:hover {
		transition: 0.1s;
		background-color: var(--darkblue) !important;
		color: var(--white) !important;
		cursor: pointer;
		filter: brightness(2);
		border: 0;
	}

	@media all and (max-width: 1024px) {
		font-size: var(--font-size-medium);
		width: var(--button-small-tablet);
		height: var(--button-height-tablet);

		img {
			width: 1rem;
		}
	}
}

.menu-button--large {
	width: var(--button-large);
	height: var(--button-height);
	margin: 0 0.3rem;
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-size: 1rem;
	color: var(--grey4);
	border: 1px solid var(--grey4);
	border-radius: 6px;
	background-color: transparent;

	img {
		display: block;
		width: 1.25rem;
	}

	&:hover {
		transition: 0.1s;
		background-color: var(--darkblue) !important;
		color: var(--white) !important;
		cursor: pointer;
		filter: brightness(2);
		border: 0;
	}

	@media all and (max-width: 1024px) {
		height: var(--button-height-tablet);
	}
}

.menu-button--larger {
	width: var(--button-larger);
	height: var(--button-height);
	margin: 0 0.3rem;
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-size: 1rem;
	color: var(--grey4);
	border: 1px solid var(--grey4);
	border-radius: 6px;
	background-color: transparent;

	img {
		display: block;
		width: 1.25rem;
	}

	&:hover {
		transition: 0.1s;
		background-color: var(--darkblue) !important;
		color: var(--white) !important;
		cursor: pointer;
		filter: brightness(2);
		border: 0;
	}

	@media all and (max-width: 1024px) {
		height: var(--button-height-tablet);
	}
}

.tippy-box[data-theme~='neuro'] {
	line-height: 1.5em;
	background-color: var(--grey1);
	color: #fff;
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	box-shadow: 0 3px 14px -0.5px rgba(0, 8, 16, 0.1);
	pointer-events: none;
}

.tippy-box[data-theme~='neuro'] > .tippy-svg-arrow {
	fill: var(--grey1);
	top: calc(-1 * (0.5rem + 7px));
}

img.clickable {
	cursor: pointer;
}

.selectable {
	user-select: text;
}

/* file upload button */
input[type='file']::file-selector-button {
	border-radius: 4px;
	padding: 0 16px;
	height: 40px;
	cursor: pointer;
	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.16);
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
	margin-right: 16px;
	transition: background-color 200ms;
}

/* file upload button hover state */
input[type='file']::file-selector-button:hover {
	background-color: #f3f4f6;
}

/* file upload button active state */
input[type='file']::file-selector-button:active {
	background-color: #e5e7eb;
}

.centered-div {
	padding-top: 15px;
	display: flex;
	justify-content: center; /* Centers horizontally */
}

.file-upload {
	display: flex;
	justify-content: space-between; /* Aligns input to the left and button to the right */
	width: 100%;
}

.upload-button {
	width: var(--button-larger);
	height: var(--button-height);
	background-color: var(--blue);
	color: var(--grey8);
	border: none;
	border-radius: 4px;
	float: right;
	font-weight: 500;
	letter-spacing: 0.1px;
	font-size: var(--font-size-medium);
	cursor: pointer;
}
