@mixin pageLoader {
    #loading-indicator {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5000;
        background-color: rgba(255,255,255,.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        #loading-center {
            background: rgba(250, 250, 250, 1.0);
            border-radius: 1em;
            border: 1px #666 solid;
            color: #666;
            padding: 2em 8em;
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h3 {
                font-weight: 300;
            }
        }
    }
}
