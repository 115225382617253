@import '../_responsive';

.message-show {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 100;
}
.message-window {
    display: flex;
    align-items: center;
    max-width: 500px;
    position: fixed;
    bottom: 0;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.22) 0 10px 25px;
    border-radius: 10px;
    color: var(--grey4);
    font-size: var(--font-size-default);
    text-align: center;
    transform: translateY(-3.1875rem);
    opacity: 1;
    transition: 300ms;
    transition-delay: 5000ms;
    z-index: 2;
    p {
        margin: 0;
        padding: 0.4rem 1rem 0.4rem 0;
        line-height: 1.66;
        max-height: 130px;
        overflow-y: auto;
        @include media("tablet") {
            padding: .8em .5em;
            font-size: .9em;
        }
    }
    .alert-buttons {
        margin: 0;
        display: flex;
        padding-left: 1rem;
        border-left: 1px solid var(--grey6);
        flex-wrap: wrap;
        justify-content: center;
        button {
            background-color: var(--white);
            color: var(--grey4);
            border: 1px solid var(--grey4);
            border-radius: 6px;
            text-align: center;
            cursor: pointer;
            padding: 0.45rem 0.8125rem;
            font-size: var(--font-size-small);
            margin: 0.2em;
            user-select: none;

            &:disabled {
                opacity: 0.5;
            }

            :first-child {
                margin-right: .5rem;
            }
            :hover {
                transition: 0.1s;
                background-color: var(--darkblue) !important;
                color: var(--white);
                cursor: pointer;
                filter: brightness(2);
            }
        }
    }
}

@media all and (max-width : 1024px) {
    .message-window {
        padding: 0.2em;
    }
}
