/*---------------------
content
1) user icon colors
2) header
3) header - project page
---------------------*/
/*---------------------
1) user icon colors
---------------------*/

.orange,
.fuchsia,
.red,
.green, // BaseUserColour
.dark,
.violet,
.yellow,
.cyan, // AdditionalUserColour
.blue,
.pink,
.lime,
.teal,
.maroon,
.turquoise,
.purple,
.navy,
.salmon,
.forestGreen,
.olive,
.chocolate {
	color: var(--white);
}

// BaseUserColour
.orange {
	background-color: var(--orange);
}
.fuchsia {
	background-color: var(--fuchsia);
}
.red {
	background-color: var(--red);
}
.green {
	background-color: var(--green);
}
// AdditionalUserColour
.dark {
	background-color: var(--dark);
}
.violet {
	background-color: var(--violet);
}
.yellow {
	background-color: var(--yellow);
}
.cyan {
	background-color: var(--cyan);
}
// ExtraUserColour
.blue {
	background-color: var(--blue);
}
.pink {
	background-color: var(--pink);
}
.lime {
	background-color: var(--lime);
}
.teal {
	background-color: var(--teal);
}
.maroon {
	background-color: var(--maroon);
}
.turquoise {
	background-color: var(--turquoise);
}
.purple {
	background-color: var(--purple);
}
.navy {
	background-color: var(--navy);
}
.salmon {
	background-color: var(--salmon);
}
.forestGreen {
	background-color: var(--forestGreen);
}
.olive {
	background-color: var(--olive);
}
.chocolate {
	background-color: var(--chocolate);
}

/*---------------------
2) header
---------------------*/
header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.9em;
	height: 6.5em;

	.neurocreate-icon {
		width: auto;
		height: 3em;
	}

	.header-menu {
		display: flex;
		align-items: center;

		#header-menu-help-button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 30px;
			height: 30px;
			border-radius: 100%;
			font-size: 1rem;
			border: 1px solid var(--grey2);
			color: var(--grey2);
			padding: 0;
			background-color: transparent;
			cursor: pointer;

			&.helpActive {
				transition: 0.1s;
				background-color: var(--darkblue) !important;
				color: var(--white);
				cursor: pointer;
				filter: brightness(2);
			}
		}

		> a {
			margin-left: 1em;
			margin-right: 1em;
			color: var(--grey4);

			img {
				vertical-align: bottom;
			}
		}

		.header-menu-user {
			display: flex;
			align-items: center;
			padding-right: calc((2rem / 2) + (4.5rem / 2));
		}
	}
}

.header-menu-icon {
	width: 39px;
	height: 39px;
	border: 1px solid var(--grey7);
	color: var(--grey7);
	border-radius: 100%;
	margin-right: 0.8em;
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;

	.no-underline {
		text-decoration: none;
	}
}

/*---------------------
3) header - project page
-----------------------*/
.project header {
	padding: 3em 4.8em 2.9em;
	height: auto;
	border-bottom: 1px solid var(--grey6);

	.header-menu {
		display: none;
	}

	.neurocreate-icon {
		height: 64px;
	}
}

/*
	ipad / tablet
*/
@media all and (min-width: 768px) and (max-width: 1024px) {
	.project header {
		padding: 2em 2.5em;
	}
	.header-menu-user {
		padding-right: 0.5em;
	}
	#header-menu-help-button {
		margin-right: 1em;
	}
}
