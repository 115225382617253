/* current user list in Project / board page */
@import '../_responsive';
@mixin userList {
	.dropdown .board-user-icon-list,
    .board-user-icon-list {
        list-style: none;
        padding: 0;
        display: flex;
        position: relative;
        width: 15px;
        height: 36px;
        @include media("tablet") {
            height: 30px;
        }
        li {
            width: 36px;
            height: 36px;
            position: absolute;
            @include media("tablet") {
                width: 30px;
                height: 30px;
                position: absolute;
            }
            &:nth-child(1) {
                z-index: 8;
                left: calc(11px * 0);
            }
            &:nth-child(2) {
                z-index: 7;
                left: calc(11px * 1);
            }
            &:nth-child(3) {
                z-index: 6;
                left: calc(11px * 2);
            }
            &:nth-child(4) {
                z-index: 5;
                left: calc(11px * 3);
            }
            &:nth-child(5) {
                z-index: 4;
                left: calc(11px * 4);
            }
            &:nth-child(6) {
                z-index: 3;
                left: calc(11px * 5);
            }
            &:nth-child(7) {
                z-index: 2;
                left: calc(11px * 6);
            }
            &:nth-child(8) {
                z-index: 1;
                left: calc(11px * 7);
            }
            a {
                display: flex;
                width: inherit;
                height: inherit;
                justify-content: center;
                align-items: center;
                color: var(--grey7);
            }
            .header-menu-icon {
                transition: all 0.2s;
            }
            &.online .header-menu-icon {
                opacity: 1;
            }
            &.offline .header-menu-icon {
                opacity: 0.2;
            }
            &:hover .header-menu-icon {
                transform: translate3d(5px, -5px, -10px) scale(1.1);
                position: relative;
                opacity: 1;
                z-index: 9;
            }
            .header-menu-icon {
                width: inherit;
                height: inherit;
                margin: 0;
            }
        }
    }
    #project-nav-controls .board-user-icon-list li .header-menu-icon {
        border: 1px solid var(--white);
    }
    #project-nav-controls .board-user-icon-list li a {
        color: var(--white);
    }
}
